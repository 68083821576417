import React from "react"
import Container from  "../components/Container";
import Card from "../components/Card";
import { Link, graphql } from "gatsby";
import { Helmet } from "react-helmet";
import  className from "../components/card.module.css"

export default ({ data }) => {
    return (
        <Container>
            <Helmet>
              <meta charSet="utf-8" />
              <title>{data.site.siteMetadata.title}</title>
              <link rel="canonical" href="https://hctrm.com" />
              <link rel="icon" type="image/png" href="../favicon.ico" sizes="16x16" />
            </Helmet>
            <Card>{data.site.siteMetadata.title}.</Card>
            <div className="main">
              {data.content.edges.map(({node}, i) => (
                <Link className={className.link} to={node.path} key={node.path}>{i + 1}. {node.title}</Link>
              ))}
            </div>
        </Container>
    )
} 

export const query = graphql`
  query {
    site: site {
      siteMetadata {
        title
      }
    }
    content: allQuote {
      edges {
        node {
          title
          path
        }
      }
    }

  }
`
